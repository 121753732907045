<template>
  <Cond :where="where" @refresh="refresh">
    <div class="mgb10">
      <el-tag
        type="primary"
        effect="dark"
        size="small"
        style="margin-right: 10px"
        >总数: {{ where.total }}</el-tag
      >
      <el-tag
        type="warning"
        effect="dark"
        size="small"
        style="margin-right: 10px"
        >本月销量: {{ tag.num }}</el-tag
      >
      <el-tag type="success" effect="dark" size="small"
        >本月销售额: {{ tag.total }}</el-tag
      >
    </div>
    <el-table
      :data="tableData"
      style="width: 100%"
      border
      size="small"
      v-loading="where.loading"
    >
      <el-table-column prop="goods_name" label="套餐" width="180">
      </el-table-column>
      <el-table-column prop="shop_name" label="店铺" width="180">
      </el-table-column>
      <el-table-column prop="ordersn" label="订单号" width="200">
      </el-table-column>
      <el-table-column prop="user_name" label="会员账号" width="200">
      </el-table-column>
      <el-table-column prop="order_status" label="订单状态" width="180">
        <template slot-scope="scope">
          <el-tag
            size="small"
            class="mgr10"
            v-if="scope.row.order_status == 0"
            type="info"
            effect="dark"
            >待支付</el-tag
          ><el-tag
            size="small"
            type="primary"
            effect="dark"
            v-else-if="scope.row.order_status == 1"
            >支付中</el-tag
          >
          <el-tag
            size="small"
            type="success"
            effect="dark"
            v-else-if="scope.row.order_status == 2"
            >支付完成</el-tag
          >
          <el-tag
            size="small"
            type="warning"
            effect="dark"
            v-else-if="scope.row.order_status == 3"
            >取消</el-tag
          >
          <el-tag
            size="small"
            type="danger"
            effect="dark"
            v-else-if="scope.row.order_status == 4"
            >退款</el-tag
          >
        </template>
      </el-table-column>
      <el-table-column prop="total_amount" label="订单金额" width="180">
      </el-table-column>
      <el-table-column prop="id" label="功能模块" width="180">
        <template slot-scope="scope">
          <div class="f-info" v-for="(m, mi) in scope.row.items" :key="mi">
            {{ m.relation_module_desc }}
          </div>
        </template>
      </el-table-column>
      <el-table-column prop="id" label="购买套餐" width="180">
        <template slot-scope="scope">
          <div v-if="scope.row.give_day > 0">
            {{ scope.row.effect_day }} Day +赠送 {{ scope.row.give_day }} Day
          </div>
          <div v-else>{{ scope.row.effect_day }} Day</div>
        </template>
      </el-table-column>
      <el-table-column prop="id" label="下单时间" width="180">
        <template slot-scope="scope">
          {{ $func.format(scope.row.create_time) }}
        </template>
      </el-table-column>
    </el-table>
    <div class="mgt10 text-center">
      <el-pagination
        @current-change="loadList"
        :current-page.sync="where.page"
        background
        layout="prev, pager, next"
        :page-size="where.pageSize"
        :total="where.total"
      >
      </el-pagination>
    </div>
  </Cond>
</template>
<script>
import Cond from "./memberbuycond.vue";
export default {
  components: {
    Cond,
  },
  data() {
    return {
      url: {
        buyorderlist: "/sysadminmember/buyorderlist",
      },
      where: {
        order_status: -1,
        page: 1,
        pageSize: 20,
        total: 0,
        loading: false,
      },
      tag: {
        num: 0,
        total: 0,
      },
      tableData: [],
    };
  },
  created() {
    this.loadList();
  },
  methods: {
    refresh: function () {
      this.where.page = 1;
      this.loadList();
    },
    loadList: function () {
      this.where.loading = true;
      this.$rq.postAction(this.url.buyorderlist, this.where).then((rs) => {
        this.where.loading = false;
        if (rs.code == 1) {
          this.tableData = [];
          this.tag.num = rs.num;
          this.tag.total = rs.total;
          for (var i in rs.list) {
            this.tableData.push(rs.list[i]);
          }
          this.where.total = parseInt(rs.pageInfo.total);
        } else {
          this.$message({
            type: "warning",
            message: rs.err,
          });
        }
      });
    },
  },
};
</script>