<template>
  <Main>
    <el-card class="box-card">
      <el-form :inline="true" class="demo-form-inline" size="mini">
        <el-form-item label="订单状态">
          <el-radio-group
            v-model="where.order_status"
            @change="$emit('refresh')"
          >
            <el-radio-button :label="-1">全部</el-radio-button>
            <el-radio-button :label="0">待支付</el-radio-button>
            <el-radio-button :label="1">支付中</el-radio-button>
            <el-radio-button :label="2">支付完成</el-radio-button>
          </el-radio-group>
        </el-form-item>
      </el-form>
      <div>
        <slot></slot>
      </div>
    </el-card>
  </Main>
</template>
<script>
import Main from "@/components/backend/common/main.vue";
export default {
  components: {
    Main,
  },
  props: {
    where: {
      type: Object,
      required: true,
      default: () => {
        return {};
      },
    },
  },
  data() {
    return {};
  },
};
</script>